/* md5: 0edc4e3a982bbc926f7b74620e38caa8 */
/* 仓库id: 241245 */
/* 版本: 0.0.1-beta.0 */
/* eslint-disable */
/* tslint:disable */

/**
 * 本文件由接口平台自动生成，请勿修改
 * 仓库地址: http://gitee/repository/editor?id=241245
 */
import axios from '@/plugins/axios'
import { downLoad } from '@/utils'
import {
  getlist,
  postpublicInfo,
  getlistRound,
  getlistScore,
  postreport,
  postsaveScore,
  postorder,
  postsaveFinalInfo,
  postupdatePublicState,
  postrepetition,
  postreportResult,
  getgetObjectScoreList,
  getget,
  putupdate,
  postupdateState,
  getlistResult
} from './model'
import {ValidateType} from './model/utils'
const isPod = process.env.NODE_ENV === 'production'

let validatelist = new ValidateType(getlist.Req, 'api-general/api-general/module/review/leader/list', 'GET')
export function list (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validatelist.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/review/leader/list',
    method: 'GET',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validatepublicInfo = new ValidateType(postpublicInfo.Req, 'api-general/api-general/module/review/leader/publicInfo', 'POST')
export function publicInfo (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validatepublicInfo.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/review/leader/publicInfo',
    method: 'POST',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validatelistRound = new ValidateType(getlistRound.Req, 'api-general/api-general/module/review/leader/listRound', 'GET')
export function listRound (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validatelistRound.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/review/leader/listRound',
    method: 'GET',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validatelistScore = new ValidateType(getlistScore.Req, 'api-general/api-general/module/review/leader/listScore', 'GET')
export function listScore (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validatelistScore.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/review/leader/listScore',
    method: 'GET',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validatereport = new ValidateType(postreport.Req, 'api-general/api-general/module/review/leader/report', 'POST')
export function report (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validatereport.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/review/leader/report',
    method: 'POST',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validatesaveScore = new ValidateType(postsaveScore.Req, 'api-general/api-general/module/review/leader/saveScore', 'POST')
export function saveScore (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validatesaveScore.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/review/leader/saveScore',
    method: 'POST',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validateorder = new ValidateType(postorder.Req, 'api-general/api-general/module/review/leader/order', 'POST')
export function order (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validateorder.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/review/leader/order',
    method: 'POST',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validatesaveFinalInfo = new ValidateType(postsaveFinalInfo.Req, 'api-general/api-general/module/review/leader/saveFinalInfo', 'POST')
export function saveFinalInfo (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validatesaveFinalInfo.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/review/leader/saveFinalInfo',
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    disabledData: true,
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validateupdatePublicState = new ValidateType(postupdatePublicState.Req, 'api-general/api-general/module/review/leader/updatePublicState', 'POST')
export function updatePublicState (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validateupdatePublicState.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/review/leader/updatePublicState',
    method: 'POST',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validaterepetition = new ValidateType(postrepetition.Req, 'api-general/api-general/module/review/leader/repetition', 'POST')
export function repetition (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validaterepetition.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/review/leader/repetition',
    method: 'POST',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validatereportResult = new ValidateType(postreportResult.Req, 'api-general/api-general/module/review/leader/reportResult', 'POST')
export function reportResult (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validatereportResult.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/review/leader/reportResult',
    method: 'POST',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validategetObjectScoreList = new ValidateType(getgetObjectScoreList.Req, 'api-general/api-general/module/review/leader/getObjectScoreList', 'GET')
export function getObjectScoreList (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validategetObjectScoreList.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/review/leader/getObjectScoreList',
    method: 'GET',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validateget = new ValidateType(getget.Req, 'api-general/api-general/module/review/leader/get', 'GET')
export function get (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validateget.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/review/leader/get',
    method: 'GET',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validateupdate = new ValidateType(putupdate.Req, 'api-general/api-general/module/review/leader/update', 'PUT')
export function update (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validateupdate.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/review/leader/update',
    method: 'PUT',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validateupdateState = new ValidateType(postupdateState.Req, 'api-general/api-general/module/review/leader/updateState', 'POST')
export function updateState (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validateupdateState.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/review/leader/updateState',
    method: 'POST',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}

let validatelistResult = new ValidateType(getlistResult.Req, 'api-general/api-general/module/review/leader/listResult', 'GET')
export function listResult (req, config = {}, filterKey = []) {
  // 生产环境去除校验，提升性能
  !isPod && validatelistResult.setFilterKey(filterKey).validate(req)
  return axios({
    url: '/api-general/module/review/leader/listResult',
    method: 'GET',
    data: req,
    ...config
  }).then(({ data }) => {
    return data
  })
}