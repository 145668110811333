export default function () {
  const { scoringMethod } = this.reviewGroupInfo
  const field = scoringMethod === 'DFFS03' ? 'reviewRank' : (scoringMethod === 'DFFS02' ? 'reviewVoteNum' : 'reviewScore')
  let minScore = 0
  let maxScore = 100
  if (scoringMethod === 'DFFS01') {
    minScore = this.reviewGroupInfo.lowestScore || 0
    maxScore = this.reviewGroupInfo.highestScore || 100
  } else if (scoringMethod === 'DFFS02') {
    maxScore = this.reviewGroupInfo.maxVote || 100
  }
  let rankOptionList = []
  if (this.dictMap[this.rankCode]) {
    rankOptionList = Object.keys(this.dictMap[this.rankCode]).map(item => ({
      label: this.dictMap[this.rankCode][item],
      value: item
    }))
  }
  const titleMap = {
    'BOE_REPORT_LIST': '项目名称',
    'BOE_REPORT_LIST_JCBDLX01': '姓名',
    'BOE_REPORT_LIST_JCBDLX02': '团队名称',
    'BOE_REPORT_LIST_JCBDLX03': '组织名称',
    'BOE_REPORT_LIST_JCBDLX04': '项目名称',
    'BOE_REPORT_LIST_JCBDLX05': '社会荣誉名称',
    'BOE_EVENT_INFO': '活动名称'
  }
  return [
    {
      title: titleMap[this.businessCode],
      [$field]: 'objectName',
      colSpan: 16,
      readOnly: true,
      rules: ['']
    },
    {
      title: '轮次序号',
      readOnly: true,
      [$field]: 'reviewRoundNum',
      rules: ['']
    },
    scoringMethod === 'DFFS03' ? {
      type: 'Select',
      title: '定级',
      [$field]: field,
      optionList: rankOptionList || [],
      rules: ['required']
    } : null,
    scoringMethod !== 'DFFS03' ? {
      type: 'Number',
      title: scoringMethod === 'DFFS02' ? '投票' : '打分',
      [$field]: field,
      props: {
        min: minScore,
        max: maxScore
      },
      rules: ['required']
    } : null,
    {
      type: 'textarea',
      title: '评审意见',
      colSpan: 24,
      props: {
        maxlength: 500
      },
      [$field]: 'reviewOpinion',
      rules: ['']
    }
  ].filter(v => v)
}