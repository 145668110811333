/* md5: 0edc4e3a982bbc926f7b74620e38caa8 */
/* 仓库id: 241245 */
/* 版本: 0.0.1-beta.0 */
/* eslint-disable */
/* tslint:disable */

/**
 * 本文件由接口平台自动生成，请勿修改
 * 仓库地址: http://gitee/repository/editor?id=241245
 */
import { string, number, date, array, object } from './utils'

export const getlist = {
  Req: {
    "endState": {
        "type": Boolean,
        "required": true
    }
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const postpublicInfo = {
  Req: {
    "reviewInfoId": {
        "type": String,
        "required": true
    }
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const getlistRound = {
  Req: {
    "userId": {
        "type": String,
        "required": true
    },
    "reviewInfoId": {
        "type": String,
        "required": true
    }
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const getlistScore = {
  Req: {
    "userId": {
        "type": String,
        "required": true
    },
    "reviewRoundId": {
        "type": String,
        "required": true
    }
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const postreport = {
  Req: {
    "reviewDetailIds": {
        "type": Array,
        "required": true
    }
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const postsaveScore = {
  Req: {
    "details": Array,
    "details[0]": Object,
    "details[0].reviewDetailId": {
        "type": String,
        "required": true
    },
    "details[0].reviewScore": Number,
    "details[0].reviewVoteNum": Number,
    "details[0].reviewRank": String,
    "details[0].reviewOpinion": String
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const postorder = {
  Req: {
    "reviewObjectId1": {
        "type": String,
        "required": true
    },
    "reviewObjectId2": {
        "type": String,
        "required": true
    }
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const postsaveFinalInfo = {
  Req: {
    "finalDatas": {
        "type": Array,
        "required": true
    },
    "finalDatas[0]": {
        "type": Object,
        "required": true
    },
    "finalDatas[0].reviewObjectId": {
        "type": String,
        "required": true
    },
    "finalDatas[0].objectRank": String,
    "finalDatas[0].objectOpinion": String
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const postupdatePublicState = {
  Req: {
    "reviewInfoId": {
        "type": String,
        "required": true
    },
    "publicState": {
        "type": String,
        "required": true
    }
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const postrepetition = {
  Req: {
    "reviewObjectIds": {
        "type": Array,
        "required": true
    }
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const postreportResult = {
  Req: {
    "reviewInfoId": {
        "type": String,
        "required": true
    }
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const getgetObjectScoreList = {
  Req: {
    "reviewObjectId": {
        "type": String,
        "required": true
    }
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const getget = {
  Req: {
    "reviewTeamId": {
        "type": String,
        "required": true
    }
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const putupdate = {
  Req: {
    "reviewTeamId": {
        "type": String,
        "required": true
    },
    "reviewTeamName": {
        "type": String,
        "required": true
    },
    "isLeaderCanModify": String,
    "scoringMethod": String,
    "highestScore": Number,
    "lowestScore": Number,
    "perVoteScore": Number,
    "scoreExplain": String,
    "maxVote": Number,
    "scoreFieldDict": String,
    "writeBackFields": String,
    "members": Array,
    "members[0]": {
        "type": Object,
        "required": true
    },
    "members[0].teamMemberId": String,
    "members[0].memberUserId": {
        "type": String,
        "required": true
    },
    "members[0].memberUserName": {
        "type": String,
        "required": true
    },
    "members[0].memberOrgId": String,
    "members[0].memberOrgName": String,
    "members[0].memberIdentity": String,
    "members[0].operateAuthority": String
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const postupdateState = {
  Req: {
    "reviewTeamIds": {
        "type": Array,
        "required": true
    },
    "publicState": {
        "type": String,
        "required": true
    }
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}

export const getlistResult = {
  Req: {
    "reviewInfoId": {
        "type": String,
        "required": true
    }
},
  Res: {
    data: {
    },
    message: string.value,
    code: number.value
  }
}