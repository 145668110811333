<template>
  <section class="iv-activity-detail">
    <header class="iv-activity-detail-header">
      <dl class="iv-main-width">
        <dt class="iv-m-r-24 iv-pos-r">
          <img :src="getCoverImage(applicationInfo.eventCoverFileId)" @error="getDefaultImg" alt="">
          <Spin size="large" fix v-if="imgShow"></Spin>
        </dt>
        <dd>
          <h3 class="iv-fs-24 iv-fc-85">{{applicationInfo.applicationName}}</h3>
          <p class="iv-m-t-20">
            <span class="iv-fs-16 iv-fc-45">发布组织：<em class="iv-fs-16 iv-fc-65">{{applicationInfo.publishOrgName}}</em></span>
            <span class="iv-fs-16 iv-fc-45 iv-m-l-32">发布人：<em class="iv-fs-16 iv-fc-65">{{applicationInfo.publisherName}}</em></span>
          </p>
          <p class="iv-fs-16 iv-fc-45 iv-m-t-10">活动时间：
            <em class="iv-fs-16 iv-fc-65">
              {{timeFormat(Number(applicationInfo.eventStartTime), 'yyyy-MM-dd HH:mm:ss')}} ~
              {{timeFormat(Number(applicationInfo.eventEndTime), 'yyyy-MM-dd HH:mm:ss')}}
            </em>
          </p>
          <p class="iv-fs-16 iv-fc-45 iv-m-t-10">
            报名截止时间：<em class="iv-fs-16 iv-fc-65">{{timeFormat(applicationInfo.applicationDeadline, 'yyyy-MM-dd HH:mm:ss')}}</em>
          </p>
          <p class="iv-fs-16 iv-fc-45 iv-m-t-10">活动地点：<em class="iv-fs-16 iv-fc-65">{{applicationInfo.eventAddress}}</em></p>
          <div class="iv-activity-detail-header-bot iv-m-t-40">
            <Button noIcon class="iv-activity-detail-header-btn iv-m-r-24" v-if="isView == false" @click="toRegister">我要报名</Button>
            <p class="iv-fs-16 iv-fc-65 iv-activity-detail-header-registration" v-html="createTimer(applicationInfo)"></p>
          </div>
        </dd>
      </dl>
    </header>
    <div class="iv-activity-detail-wrap iv-m-t-24 iv-pos-r">
      <!-- 主体内容 -->
      <article class="iv-activity-detail-contetn iv-pull-left iv-m-b-24">
        <Tab v-model="currentName" :navList="tabList" @on-click="switchTab"></Tab>
        <component
          :is="currentName"
          :ref="currentName"
          :data="applicationInfo.eventDetails"
          :isExistOrg="applicationInfo.isExistOrg"
          :publishOrgId="applicationInfo.publishOrgId"
          :publishOrgName="applicationInfo.publishOrgName"
          :applicationExplain="applicationInfo.applicationExplain"
          :fileId="applicationInfo.applicationFileGroupId"
          :applicationObjectList="applicationInfo.applicationObjectList"
          :applicationInfoId="applicationInfo.applicationInfoId"
          :bizLineCode="applicationInfo.bizLineCode">
        </component>
      </article>
      <!-- 我的报名历史 -->
      <History v-if="!objectId" ref="History" :infoId="applicationInfoId"></History>
      <Spin size="large" fix v-if="spinShow"></Spin>
    </div>
    <!-- 打分 -->
    <ScoreModal ref="ScoreModal" v-if="businessId"></ScoreModal>
    <FooterOperator>
      <div class="iv-foot-wrap">
        <span v-if="showTips && isView == false && !objectId" class="iv-fs-14 iv-fc-45 iv-foot-tips">
          本活动支持重复报名，<a href="javascript:void(0);" @click="repeatSubmit">点击此处</a>提交新报名
        </span>
        <!-- <Button v-if="businessId && score != 'result'" class="iv-m-r-8" type="save" @click="scoreHandler">打分</Button> -->
        <Button 
          v-if="currentName === 'Registration' && !isDisabled && canModify && isSignUp == 'yes' && isView == false" 
          type="submit" 
          class="iv-m-r-8" 
          @click="submitRegist"
        >提交报名</Button>
        <Button 
          v-if="currentName === 'Registration' && isDisabled && canModify && isSignUp == 'yes' && isView == false" 
          type="cancel" class="iv-m-r-8" 
          @click="cancelSingUp"
        >取消报名</Button>
        <Button v-if="isView == false" type="back" @click="back">返回</Button>
      </div>
    </FooterOperator>
  </section>
</template>

<script>
import Tab from '@/components/tab'
import ScoreModal from '@/views/review/modal'
import { timeFormat, getToken } from '@/utils'
import Introduce from './components/introduce.vue'
import Registration from './components/registration.vue'
import History from './components/history'
import FooterOperator from '@/components/footer-operator'
  
export default {
  data () {
    return {
      currentName: this.$route.query.currentName? this.$route.query.currentName : 'Introduce',
      applicationInfo: {},
      showTips: false,
      isDisabled: false,
      canModify: true,
      isView: false,
      applicationInfoId: '',
      spinShow: false,
      imgShow: false
    }
  },
  async created () {
    this.initTab()
    this.backUrl = this.$route.query.backUrl
    this.isView = this.$route.query.isView
    this.score = this.$decode(this.$route.query.score)
    this.objectId = this.$route.query.objectId
    this.businessId = this.$decode(this.$route.query.businessId)
    this.isSignUp = this.$route.query.isSignUp
    //评审打分获取applicationInfoid
    if (this.businessId) {
      this.applicationInfoId = await this.getBasicInfoForReview()
    } else {
      this.applicationInfoId = this.$route.query.applicationInfoId
    }
    this.getActivityDetail()
  },
  provide () {
    return {
      showRepeatTips: this.showRepeatTips,
      back: this.back,
      updateHistory: this.updateHistory
    }
  },
  methods: {
    timeFormat,
    //评审打分 获取applicationInfoid
    getBasicInfoForReview () {
      this.spinShow = true
      return new Promise((resolve, reject) => {
        this.$ajax({
          url: 'api-general/module/v2event/personalCenter/getApplicationInfoId',
          method: 'post',
          data: {
            reportListId: this.businessId
          },
          headers: {
            'Content-Type': 'application/json'
          },
          disabledData: true
        }).then(({data}) => {
          resolve(data.data.applicationInfoId || '')
        })
      })
    },
    getCoverImage (photo) {
      if (photo) {
        return `api-uum/file/downloadFile?fileId=${photo}&access_token=${getToken()}`
      }
    },
    getDefaultImg (e) {
      e.target.src = require('@/assets/images/course/cover-image-1.png')
    },
    //获取活动详情
    getActivityDetail () {
      // this.imgShow = true
      this.$ajax({
        url: 'api-general/module/v2event/personalCenter/application/getApplication',
        method: 'get',
        data: {
          applicationInfoId: this.applicationInfoId
        }
      }).then(({data}) => {
        this.applicationInfo = data.data
        //评审过来的  不能查看其它奖项  手动置空
        if (this.objectId) {
          this.applicationInfo.applicationObjectList = []
          this.switchTab('Registration')
        }
        this.spinShow = false
        this.imgShow = false
      })
    },
    //我要报名
    toRegister () {
      this.currentName = 'Registration'
    },
    //tab切换
    switchTab (name) {
      this.currentName = name
    },
    //是否显示重复报名的提示 & 按钮显示名字
    showRepeatTips (val, canModify, isCancel) {
      this.showTips = val == '1'
      //代表已经填写过且可以修改
      if (canModify == '1') {
        this.canModify = true
        this.isDisabled = isCancel == '1'
      } else if (canModify == '0') { //名单已确认 不可以修改
        this.canModify = false
        this.showTips = false
      } else if (canModify == 'add') { //解决一个奖项不显示提交按钮后  切换到另一个状态没有重置的问题
        this.canModify = true
        this.isDisabled = false
      }
    },
    //提交后更新报名历史
    updateHistory () {
      if (!this.objectId) {
        this.$refs.History.getRegistrationHistory()
      }
    },
    //提交报名
    submitRegist () {
      this.$refs.Registration.submit()
    },
    //重新提交
    repeatSubmit () {
      //重置数据
      this.$refs.Registration.initialize()
      //按钮状态
      this.isDisabled = false
    },
    //取消报名
    cancelSingUp () {
      this.$refs.Registration.cancelTeamSignUp()
      //按钮状态
      this.isDisabled = false
    },
    //返回
    back () {
      if (this.backUrl == 'reviewGrade') {
        if (this.$route.query.score === 'score') {
          const query = JSON.parse(sessionStorage.getItem('reviewScoreQuery') || '{}')
          this.$router.push({
            name: 'reviewGrade',
            query: query,
            params: {
              reviewRoundObjectId: this.$route.query.reviewRoundObjectId,
              currentIndex: this.$route.query.currentIndex
            }
          })
        } else {
          this.$router.go(-1)
        }
      } else {
        this.$router.push({
          name: this.backUrl || 'activityList',
          query: {
            currentType: this.$route.query.currentType || ''
          }
        })
      }
    },
    //计算截止时间
    createTimer (item) {
      const currentDate = Date.now()
      const distance = item.applicationDeadline - currentDate
      let info = ''
      if (distance >= 0) {
        //天
        const day = Math.floor(distance / (1000 * 60 * 60 * 24))
        //时
        const hour = Math.floor((distance / (1000 * 60 * 60)) % 24)
        //分
        const minutes = Math.floor((distance / (1000 * 60)) % 60)
        info = `报名还剩${day ? '<b class="iv-ac-red">' + day + '</b>' + '天' : ''}${hour ? '<b class="iv-ac-red">' + hour + '</b>' + '时' : ''}${minutes ? '<b class="iv-ac-red">' + minutes + '</b>' + '分' : ''}`
      }
      return info
    },
    initTab () {
      this.tabList = [{
        label: '活动介绍',
        name: 'Introduce'
      }, {
        label: '活动报名',
        name: 'Registration'
      }]
    }
  },
  components: {
    Tab,
    Introduce,
    Registration,
    History,
    ScoreModal,
    FooterOperator
  }
}
</script>

<style>
.iv-ac-red {
  color: #fa2900;
  margin: 0 2px;
}
</style>
<style lang="scss" scoped>
@import '@/assets/css/base/variable.scss';
.iv-activity-detail {
  min-height: calc(100vh - 64px);
  background-color: #F5F7FA;
  padding-bottom: 30px;
  .iv-activity-detail-header {
    background-color: #fff;
    padding: 48px 0;
    dl {
      display: flex;
      align-items: center;
      height: 281px;
      dt {
        width: 500px;
        height: 281px;
        border-radius: 8px;
        opacity: 0.8;
        img {
          width: 100%;
          height: 100%;
        }
      }
      dd {
        .iv-m-t-10 {
          margin-top: 10px;
        }
        .iv-activity-detail-header-registration {
          b {
            color: #fa2900;
            margin: 0 2px;
          }
        }
        .iv-activity-detail-header-btn {
          height: 48px;
          color: #fff !important;
          background-color: $iv-main-color !important;
          &:hover {
            opacity: 0.8;
          }
        }
        .iv-activity-detail-header-bot {
          display: flex;
          align-items: center;
        }
      }
    }
  }
  .iv-activity-detail-wrap {
     width: 1440px;
     margin: 0 auto;
    .iv-activity-detail-contetn {
      width: 1068px;
      background-color: #fff;
      padding: 0 24px 22px;
      border-radius: 8px;
    }
    &::after {
      content: ".";
      display: block;
      height: 0;
      visibility: hidden;
      clear: both;
    }
  }
  .iv-foot-wrap {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    .iv-foot-tips {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
</style>
<style>
@media screen and (max-width: 1366px) {
  .iv-activity-detail-wrap {
    width: 1200px!important;
  }
  .iv-activity-detail-contetn {
    width: 876px!important;
  }
}
</style>