import { cloneDeep } from 'lodash'
import { getToken } from '@/utils'

export default {
  methods: {
    //获取详情  个人
    getActivityPersonalDetail (signUpId) {
      this.$ajax({
        url: 'api-general/module/v2event/personalCenter/signUp/getIndividualSignUp',
        method: 'get',
        data: {
          signUpId: signUpId,
          orgId: this.orgId
        }
      }).then(async ({data}) => {
        const newData = data.data
        this.formItem = newData
        // 工会传发布组织id
        if (this.bizLineCode === 'YWX03') {
          this.formItem.recommendOrgId = this.publishOrgId
        }
        console.log('这个位置是：', this.formItem.recommendOrgId)
        if (this.formItem.age) {
          this.formItem.age = this.formItem.age.toString()
        }
        //通过isCancel来判断是否为取消报名  1是 0否
        this.isDisabled = this.formItem.isCancel == '1'
        //按钮状态
        this.showRepeatTips(this.currentPrize.allowRepeatSignUp, this.formItem.canModify ? '1' : '0', this.formItem.isCancel)
        //获取头像
        if (newData.photo) {
          this.photo_groupId = newData.photo
          this.photoSrc = `api-uum/file/downloadFile?fileId=${newData.photo}&access_token=${getToken()}`
        }
        this.getDynamicFormInfo()
        //获取底部附件
        if (newData.attachmentGroupId) {
          this.uploadFile_groupId = newData.attachmentGroupId
          const files = await this.getActivityFiles(newData.attachmentGroupId)
          this.$set(this.formItem, 'uploadFile', files)
        }
      })
    },
    // 选择活动个人 人员信息姓名默认当前本人
    async getcurrentName () {
      this.$set(this.formItem, 'userId', sessionStorage.getItem('userId'))
      this.$set(this.formItem, 'userName', sessionStorage.getItem('userName'))
      this.$set(this.formItem, 'userCode', sessionStorage.getItem('userCode'))
      this.$set(this.formItem, 'dutyName', sessionStorage.getItem('userDutyName'))
      this.$set(this.formItem, 'partyOrgName', sessionStorage.getItem('orgName'))
      this.$set(this.formItem, 'partyOrgId', sessionStorage.getItem('orgId'))
      this.$set(this.formItem, 'political', sessionStorage.getItem('userPolitical'))
      //从uum取数据
      if (this.formItem.userCode) {
        const result = await this.getUserInfoFromUum(this.formItem.userCode)
        this.$set(this.formItem, 'gender', result.sex)
        this.$set(this.formItem, 'employeeNumber', result.userCode)
        this.$set(this.formItem, 'idCardNum', result.idCardNum)
        this.$set(this.formItem, 'nationality', result.nationality)
        this.$set(this.formItem, 'nationalityStr', result.nationalityStr)
        this.$set(this.formItem, 'education', result.highestEducation)
        this.$set(this.formItem, 'birthday', result.birthday)
        this.$set(this.formItem, 'inDeptDate', result.work4Company)
        this.$set(this.formItem, 'email', result.email)
        this.$set(this.formItem, 'mobile', result.mobile)
        this.$set(this.formItem, 'band', result.bBandId)
        // this.$set(this.formItem, 'companyOrgName', result.orgPath)
        //公司名字段错误 应是orgName
        this.$set(this.formItem, 'companyOrgName', result.orgName)
        this.$set(this.formItem, 'jobName', result.jobName)
        this.$set(this.formItem, 'workPostDate', result.posBegDate)
        this.$set(this.formItem, 'dutyCode', result.dutyCode)
        this.formItem.idCardNum && this.computedAge(this.formItem.idCardNum)
        this.formItem.inDeptDate && this.changeHandler(this.formItem.inDeptDate)
      }
    },
    //手动选择入司时间时  计算司龄
    changeDate ({key}, val) {
      if (key === 'inDeptDate') {
        this.changeHandler(val)
      }
    },
    //根据身份证号计算年龄
    changeInput ({key}, val) {
      if (key === 'idCardNum' && val.length == 18) {
        this.computedAge(val)
      }
    },
    //计算年龄
    computedAge (val) {
      const date = val.substring(6, 14).replace(/(\d{4})(\d{2})(\d{2})/, function (str, $1, $2, $3) {
        return `${$1}/${$2}/${$3}`
      })
      const distance = new Date().getTime() - new Date(date).getTime()
      const age = Math.floor(distance / 1000 / 60 / 60 / 24 / 365)
      this.$set(this.formItem, 'age', String(age))
    },
    //选择入司时间后 计算司龄
    changeHandler (val) {
      val = new Date(val)
      const distance = new Date().getTime() - val.getTime()
      const sourceAge = distance / 1000 / 60 / 60 / 24 / 365
      this.$set(this.formItem, 'workingAge', Math.floor(sourceAge))
    },
    //过滤掉多余的字段
    filterParams (obj) {
      //受保护的key 就算是没配置表单 也不能删
      const protectedKeys = ['recommendOrgId', 'userId', 'fillInUserId', 'userId', 'partyOrgId']
      const formKeys = []
      this.dynamicList.forEach((item) => {
        item.formPropertyList.forEach(val => {
          formKeys.push(val.propertyCode)
        })
      })
      Object.keys(obj).forEach(val => {
        if (!formKeys.includes(val) && !protectedKeys.includes(val)) {
          delete obj[val]
        }
      })
    },
    //活动个人提交
    activitySingleSubmit (signUpId) {
      const params = this.dateFormat(cloneDeep(this.formItem))
      this.filterParams(params)
      this.$ajax({
        url: `api-general/module/v2event/personalCenter/signUp/${signUpId ? 'editIndividualSignUp' : 'submitIndividualSignUp'}`,
        method: signUpId ? 'put' : 'post',
        data: {
          ...params,
          signUpId: signUpId,
          userName: Array.isArray(params.userName) ? params.userName[0].userName : params.userName,
          attachmentGroupId: this.uploadFile_groupId,
          photo: this.photo_groupId,
          applicationObjectId: this.currentPrize.applicationObjectId,
          applicationInfoId: this.applicationInfoId,
          employeeNumber: this.formItem.userCode || this.formItem.userId //提交需要默认携带提交人的登录账号
        },
        headers: {
          'Content-Type': 'application/json'
        },
        disabledData: true
      }).then(({data}) => {
        this.$Message.success('提交成功')
        this.isDisabled = true
        this.getActivityPersonalDetail(data.data.signUpId)
        this.setSignUpIdAfterSubmit(data.data.signUpId)
        this.updateHistory()
      })
    },
    //从uum获取用户信息
    getUserInfoFromUum (userCode) {
      return new Promise((resolve, reject) => {
        this.$ajax({
          url: 'api-uum/module/v2event/getUumUser',
          method: 'GET',
          data: {
            userCode: userCode
          }
        }).then(({data}) => {
          resolve(data.data)
        })
      })
    },
    //获取附件id 第一次上传附件 本地生产uuid
    getUuid () {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        let r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8)
        return v.toString(16)
      })
    },
    //获取文件信息
    getActivityFiles (groupId) {
      return new Promise((resolve, reject) => {
        this.$ajax({
          url: 'api-uum/file/listFilesByGroupId',
          method: 'GET',
          data: {
            isPage: false,
            groupId: groupId
          }
        }).then(({data}) => {
          resolve(data.data || [])
        })
      })
    }
  }
}
