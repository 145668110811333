<template>
  <div class="iv-activity-introduce iv-m-t-24">
    <div class="iv-activity-introduce-content iv-fc-65 iv-fs-16" v-html="eventDetails"></div>
    <!-- 报名条件说明 -->
    <div v-if="applicationExplain" class="iv-activity-introduce-explain iv-m-t-24">
      <h3 class="iv-fs-16 iv-fc-85">报名条件说明</h3>
      <p class="iv-activity-gray iv-m-t-16 iv-fs-16 iv-fc-65">{{applicationExplain}}</p>
    </div>
    <!-- 附件资料 -->
    <div class="iv-activity-introduce-attachment iv-m-t-24">
      <h3 class="iv-fs-16 iv-fc-85">附件资料</h3>
      <ul class="iv-activity-gray iv-m-t-16">
        <li 
          v-for="item in fileList"
          :key="item.fileId"
          class="iv-main-color iv-fs-14 iv-pointer" 
          @click="downloadFile(item)"
        >
          <IconFont type="link" style="margin-right: 10px;"></IconFont>
          <span>{{item.fileName}}</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import IconFont from '@/components/iconfont'

export default {
  props: {
    data: {
      type: String,
      default: ''
    },
    fileId: {
      type: String,
      default: ''
    },
    applicationExplain: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      eventDetails: '',
      fileList: []
    }
  },
  created () {
    this.eventDetails = unescape(this.data || '')
  },
  watch: {
    data () {
      this.eventDetails = unescape(this.data || '')
    },
    fileId: {
      async handler () {
        if (this.fileId) {
          this.fileList = await this.getActivityFiles(this.fileId)
        }
      },
      immediate: true
    }
  },
  methods: {
    //下载附件
    downloadFile (size) {
      let url = `api-uum/file/downloadFile?fileId=${size.fileId}&access_token=${sessionStorage.getItem('token')}`
      window.open(url)
    },
    //获取文件信息
    getActivityFiles (groupId) {
      return new Promise((resolve, reject) => {
        this.$ajax({
          url: 'api-uum/file/listFilesByGroupId',
          method: 'GET',
          data: {
            isPage: false,
            groupId: groupId
          }
        }).then(({data}) => {
          resolve(data.data || [])
        })
      })
    }
  },
  components: {
    IconFont
  }
}
</script>

<style>
.iv-activity-introduce-content img {
  text-align: center;
  max-width: 100%;
  max-height: 100%;
}
</style>
<style lang="scss" scoped>
.iv-activity-introduce {
  .iv-activity-introduce-content {
    overflow: hidden;
  }
  .iv-activity-gray {
    padding: 20px;
    border-radius: 8px;
    background-color: #F5F7FA;
  }
}
</style>