<template>
  <aside class="iv-activity-my-registration iv-pull-right">
    <h3 class="iv-fs-16 iv-fc-85 iv-border-color iv-p-b-16 iv-p-t-16">本活动报名历史</h3>
    <ul>
      <li
        v-for="(item, index) in list"
        :key="index"
        class="iv-p-20 iv-m-t-20 iv-pointer"
        @click="toDetailPage(item)"
      >
        <h4 class="iv-fc-65 iv-fs-14">{{item.applicationName}}</h4>
        <p class="iv-fc-65 iv-fs-14 iv-m-t-8">{{item.objectName}}</p>
        <p class="iv-fs-12 iv-fc-45 iv-m-t-8">报名时间：{{item.createTime}}</p>
      </li>
    </ul>
    <Page
      v-if="showPage && list.length"
      :total="total"
      :current="currentPage"
      :pageSize="pageSize"
      :showGo="false"
      :show-total="false"
      :showSizer="false"
      :showElevator="false"
      class="iv-m-t-20"
      @on-change="changePage">
    </Page>
  </aside>
</template>

<script>
import Page from '@/components/page'

export default {
  props: {
    infoId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      list: [],
      showPage: false,
      currentPage: 1,
      pageSize: 3,
      total: 0,
      applicationInfoId: ''
    }
  },
  watch: {
    infoId: {
      handler () {
        this.applicationInfoId = this.infoId
        if (this.applicationInfoId) {
          this.getRegistrationHistory()
        }
      },
      immediate: true
    }
  },
  methods: {
    //获取报名历史列表
    getRegistrationHistory () {
      this.$ajax({
        url: 'api-general/module/v2event/listSignUpHistory',
        method: 'get',
        data: {
          currentPage: this.currentPage,
          pageSize: this.pageSize,
          applicationInfoId: this.applicationInfoId
        }
      }).then(({data}) => {
        this.list = data.data
        this.total = data.count
      })
    },
    //详情页面
    toDetailPage (item) {
      const isView = item.applicationDeadline - Date.now() < 0 && item.infoState === 'SBZT02'
      this.$router.replace({
        name: 'activityDetail',
        query: {
          applicationInfoId: item.applicationInfoId,
          applicationObjectId: item.applicationObjectId,
          signUpId: item.signUpId,
          currentName: 'Registration',
          isSignUp: 'yes',
          isView
        }
      })
      this.$router.go(0)
    },
    //分页
    changePage (num) {
      this.currentPage = num
    }
  },
  components: {
    Page
  }
}
</script>

<style lang="scss" scoped>
.iv-activity-my-registration {
  width: 350px;
  background-color: #fff;
  padding: 0 24px 24px;
  border-radius: 8px;
  h3 {
    border-bottom: 1px solid;
  }
  ul {
    li {
      background: #F5F7FA;
      border-radius: 8px;
    }
  }
}
</style>
<style>
@media screen and (max-width: 1366px) {
  .iv-activity-my-registration {
    width: 300px!important;
  }
}
</style>
