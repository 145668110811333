<template>
  <section class="iv-active-regist">
    <header class="iv-active-regist-header iv-p-t-24">
      <p v-if="!objectId" class="iv-fs-16 iv-fc-65" style="margin-bottom: 10px;">选择报名项目：</p>
      <ul v-if="isSignUp != 'no'">
        <li
          v-for="(item, index) in prizeList"
          :key="index"
          class="iv-fs-16 iv-fc-65 iv-border-color iv-pointer"
          :class="{'iv-m-l-20': index !== 0, 'iv-header-active-li': item.applicationObjectId === applicationObjectId}"
          @click="switchPrize(item)"
        >{{item.objectName}}</li>
      </ul>
      <p v-if="isSignUp == 'no'" class="iv-fs-24 iv-ac-red">非本组织发布的活动，请联系活动发布组织报名。</p>
    </header>
    <LayoutTitle
      v-if="isSignUp != 'no' && item.isEnabled == 1 && item.formPropertyList.length"
      v-for="(item, index) in dynamicList"
      :key="item.groupId"
      :title="item.groupName"
      :showBtn="item.groupType == 2"
      :readOnly="isDisabled || isView"
      :btnList="btnList"
      @on-click="addTeamMember"
    >
      <FormLayout
        v-if="item.groupType == 1"
        ref="FormLayout"
        :readOnly="isDisabled || isView"
        :model="formItem"
        :list="generatePage(index)"
        @on-blur="changeInput"
        @on-change="changeDate"
        @on-change-tree="checkPerson"
        @on-upload-success="onUploadSucess(...arguments)">
      </FormLayout>
      <dragTable
        v-if="item.groupType == 2"
        noSearch
        ref="DragTable"
        class="iv-m-t-16"
        :immediate="true"
        :showSelect="false"
        :showPage="false"
        :showEdit="false"
        :showDelete="!isDisabled && !isView"
        :columns="generatePage(index)"
        :tableData="teamMembers">
      </dragTable>
    </LayoutTitle>
    <!-- 选人 -->
    <SelectMember ref="SelectMember" :treeType="treeType" :hideSelectInput="true" v-model="teamMembers" :listProps="listProps" @on-change-all="confirmSelect"></SelectMember>
  </section>
</template>

<script>
import { cloneDeep } from 'lodash'
import {timeFormat, getToken} from '@/utils'
import generateList from './registration.js'
import FormLayout from '@/components/layout/form'
import dragTable from '@/components/drag-table-inline'
import SelectMember from '@/components/select-input-member'
import LayoutTitle from '@/components/layout/layout-title'
import registrationLogic from './registration-logic.js'

export default {
  mixins: [registrationLogic], //存放个人报名相关逻辑
  props: {
    bizLineCode: {
      type: String,
      default: ''
    },
    applicationObjectList: {
      type: Array,
      default () {
        return []
      }
    },
    applicationInfoId: {
      type: String,
      default: ''
    },
    publishOrgId: {
      type: String,
      default: ''
    },
    publishOrgName: {
      type: String,
      default: ''
    },
    isExistOrg: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isDisabled: false,
      currentPrize: {},
      prizeList: [],
      dynamicFormId: '',
      dynamicFormVersion: '',
      dynamicList: [],
      applicationObjectId: '',
      photo_groupId: '',
      uploadFile_groupId: '',
      formItem: {},
      teamMembers: [],
      orgId: '',
      orgName: '',
      photoSrc: '',
      isView: false,
      treeType: {},
      listProps: {},
      btnList: [],
      politicsOrgName: ''
    }
  },
  created () {
    this.btnList = [{
      label: '添加成员',
      icon: 'append',
      status: 'addMember'
    }]
    this.currentType = this.$route.query.currentType
    this.isSignUp = this.$route.query.isSignUp //是否为本组织  yes是  no不是
    this.isView = this.$route.query.isView
    this.isDisabled = this.$route.query.type == 'see'
    const idMap = JSON.parse(sessionStorage.getItem('idMap'))
    const nameMap = JSON.parse(sessionStorage.getItem('nameMap'))
    //获取组织id和名称
    this.orgId = this.$decode(sessionStorage.getItem(idMap[this.bizLineCode]))
    this.orgName = this.$decode(sessionStorage.getItem(nameMap[this.bizLineCode]))
    this.politicsOrgName = sessionStorage.getItem('politicsOrgName')
    this.setSelectMemberComponentProps()
    this.setRecommendInfo()
    //获取奖项
    this.pulloutRelationLogic()
    //评审打分
    this.objectId = this.$route.query.objectId
    if (this.objectId) {
      this.getSingUpIdForAudit()
    }
  },
  watch: {
    applicationObjectList () {
      this.pulloutRelationLogic()
    }
  },
  provide () {
    return {
      // saveItem: this.saveItem,
      // deleteItem: this.deleteItem,
      // removeAdd: this.removeAdd
    }
  },
  inject: ['showRepeatTips', 'updateHistory'],
  methods: {
    //抽离相关逻辑
    pulloutRelationLogic () {
      this.prizeList = cloneDeep(this.applicationObjectList || [])
      if (this.prizeList.length) {
        //地址栏有applicationObjectId时  自动切换到对应的活动奖项
        const fromApplicationObjectId = this.$route.query.applicationObjectId
        let _index = 0
        if (fromApplicationObjectId) {
          _index = this.prizeList.findIndex(item => item.applicationObjectId == fromApplicationObjectId)
        }
        _index = _index > -1 ? _index : 0
        //点击历史报名需要更换signUpId
        if (fromApplicationObjectId && this.$route.query.signUpId) {
          this.prizeList[_index].signUpId = this.$route.query.signUpId
        }
        this.currentPrize = this.prizeList[_index]
        this.dynamicFormId = this.prizeList[_index].dynamicFormId
        this.dynamicFormVersion = this.prizeList[_index].dynamicFormVersion
        this.applicationObjectId = this.prizeList[_index].applicationObjectId
        this.getActivityItemDetail(this.currentPrize)
      }
    },
    //供父级重置数据  提交新报名使用
    initialize () {
      this.setRecommendInfo()
      this.signUpId = ''
      this.teamMembers = []
      this.transferDataType()
      this.isDisabled = false
      this.getDynamicFormInfo('initialize')
      //切换到个人时 默认填充个人信息
      if (this.currentPrize.basicFormType == 'JCBDLX11') {
        this.getcurrentName()
      }
    },
    //初始化数据
    setRecommendInfo () {
      console.log('-----------000000000-------------', this.orgName, this.publishOrgName)
      this.formItem = {
        //字段变了，为了不对后续产生影响，把两个字段都传过去 recommendOrgName/orgName
        //工会的传发布组织id
        recommendOrgName: this.bizLineCode === 'YWX03' ? this.publishOrgName : this.orgName,
        orgName: this.politicsOrgName,
        recommendOrgId: this.bizLineCode === 'YWX03' ? this.publishOrgId : this.orgId,
        fillInUserName: this.$decode(sessionStorage.getItem('userName')),
        fillInUserId: this.$decode(sessionStorage.getItem('userId')),
        fillInUserEmail: this.$decode(sessionStorage.getItem('userEmail')),
        fillInUserPhone: this.$decode(sessionStorage.getItem('userPhone'))
      }
      this.photo_groupId = ''
      this.uploadFile_groupId = ''
      this.photoSrc = ''
      this.teamMembers = []
    },
    //计算年龄
    calculateAge(birthDateString) {
      var today = new Date();
      var birthDate = new Date(birthDateString);
      var age = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
      }
      return age;
    },
    //切换奖项
    switchPrize (item) {
      this.currentPrize = item
      this.signUpId = item.signUpId
      this.dynamicFormId = item.dynamicFormId
      this.dynamicFormVersion = item.dynamicFormVersion
      this.applicationObjectId = item.applicationObjectId
      //每次切换前重置数据
      this.setRecommendInfo()
      this.getActivityItemDetail(item)
    },
    //评审打分获取singUpId
    getSingUpIdForAudit () {
      this.$ajax({
        url: 'api-general/module/v2event/personalCenter/application/getSignUpId',
        method: 'get',
        data: {
          listItemId: this.objectId
        }
      }).then(({data}) => {
        this.dynamicFormId = data.data.dynamicFormId
        this.dynamicFormVersion = data.data.dynamicFormVersion
        this.getActivityItemDetail({
          ...data.data,
          basicFormType: this.$route.query.businessCode
        })
      })
    },
    //如果是团队报名且设置了发展党员可报名需添加按钮
    addButtonOnSetGroup (item) {
      const isDevelop = !!(item.allowPmdUser && (item.allowPmdUser.indexOf('20') != -1 || item.allowPmdUser.indexOf('30') != -1))
      if (this.bizLineCode == 'YWX01' && item.basicFormType == 'JCBDLX12' && isDevelop) {
        this.btnList.push({
          label: '添加发展党员',
          icon: 'append',
          status: 'addDevelopMember'
        })
      } else {
        this.btnList = [{
          label: '添加成员',
          icon: 'append',
          status: 'addMember'
        }]
      }
    },
    //获取活动项目详情
    getActivityItemDetail (item) {
      this.addButtonOnSetGroup(item)
      if (item.signUpId) {
        if (item.basicFormType == 'JCBDLX12' || item.basicFormType == 'BOE_EVENT_INFO_JCBDLX12') { //团队
          this.getActivityGroupDetail(item.signUpId)
        } else { //个人
          this.getActivityPersonalDetail(item.signUpId)
        }
      } else { //新增
        this.isDisabled = false
        //切换到个人时 默认填充个人信息
        if (item.basicFormType == 'JCBDLX11') {
          this.getcurrentName()
        }
        this.getDynamicFormInfo()
        this.showRepeatTips(item.allowRepeatSignUp, 'add')
      }
    },
    //获取详情  团队
    getActivityGroupDetail (signUpId) {
      this.$ajax({
        url: 'api-general/module/v2event/personalCenter/signUp/getGroupSignUp',
        method: 'get',
        data: {
          signUpId: signUpId,
          orgId: this.orgId
        }
      }).then(async ({data}) => {
        const newData = data.data
        this.formItem = newData
        //通过isCancel来判断是否为取消报名  1是 0否
        this.isDisabled = this.formItem.isCancel == '1'
        //按钮状态
        this.showRepeatTips(this.currentPrize.allowRepeatSignUp, this.formItem.canModify ? '1' : '0', this.formItem.isCancel)
        //团队成员
        this.teamMembers = newData.teamMemberList.map(val => ({
          ...val,
          userName: val.memberUserName,
          userId: val.memberUserId
        }))
        //获取头像
        if (newData.photo) {
          this.photo_groupId = newData.photo
          this.photoSrc = `api-uum/file/downloadFile?fileId=${newData.photo}&access_token=${getToken()}`
        }
        this.getDynamicFormInfo()
        //获取底部附件
        if (newData.attachmentGroupId) {
          this.uploadFile_groupId = newData.attachmentGroupId
          const files = await this.getActivityFiles(newData.attachmentGroupId)
          this.$set(this.formItem, 'uploadFile', files)
        }
      })
    },
    //获取动态表单接口
    getDynamicFormInfo (status) {
      if (!this.dynamicFormId) return
      this.dynamicList = []
      this.$ajax({
        url: 'api-general/dynamicform/form/getFormStructure',
        method: 'get',
        data: {
          formId: this.dynamicFormId,
          version: this.dynamicFormVersion
        }
      }).then(({data}) => {
        this.dynamicList = data.data.formGroupList || []
        this.transferDataType()
      })
    },
    //生成表单和表格
    generatePage (index) {
      return generateList.call(this, index)
    },
    //转换对应的值
    transferDataType () {
      if (!this.dynamicList.length) return
      this.dynamicList.forEach((item) => {
        item.formPropertyList.forEach(val => {
          const value = this.formItem[val.propertyCode]
          if (val.widgetType == 'InputNumber') {
            this.$set(this.formItem, val.propertyCode, value ?? null)
          }
          if (val.widgetType == 'DatePicker') {
            this.$set(this.formItem, val.propertyCode, timeFormat(value))
          }
        })
      })
    },
    //添加团队成员
    addTeamMember (item) {
      if (item.status == 'addMember') { //添加成员
        this.setSelectMemberComponentProps()
        this.$methods('SelectMember', 'onShowTree')
      } else if (item.status == 'addDevelopMember') { //添加发展党员
        this.setDevelopPartyMemberParams()
        this.$methods('SelectMember', 'onShowTree')
      }
    },
    //弹框确认选择
    confirmSelect (list) {
      this.teamMembers = list.map(val => ({
        memberUserName: val.userName,
        memberUserId: val.userId,
        employeeNumber: val.userCode,
        userName: val.userName,
        userId: val.userId
      }))
    },
    //点击取消
    removeAdd (index, item) {
      if (item._add) {
        this.teamMembers.splice(index, 1)
      } else {
        for (let i in item) {
          if (Object.prototype.toString.call(item[i]) === '[object Date]') {
            item[i] = timeFormat(item[i])
          }
        }
      }
    },
    //保存
    saveItem (item) {
      const index = item._index
      for (let i in item) {
        if (Object.prototype.toString.call(item[i]) === '[object Date]') {
          item[i] = timeFormat(item[i])
        }
        if (i.indexOf('_') != -1) {
          delete item[i]
        }
      }
      item.isEdit = false
      this.teamMembers.splice(index, 1, item)
      return Promise.resolve({item, showMessage: 'message'})
    },
    //删除
    deleteItem (item) {
      this.teamMembers.splice(item._index, 1)
      return Promise.resolve(item)
    },
    //选择单位
    checkPerson (data, key) {
      if (key == 'orgName') {
        this.formItem.orgId = data[0].id
      } else if (key == 'recommendOrgName') {
        this.formItem.recommendOrgId = data[0].id
      }
    },
    //上传成功后设置groupId
    onUploadSucess (file, key) {
      if (file.success) {
        if (key === 'photo') {
          this[`${key}_groupId`] = file.response.data.fileId
        } else {
          this[`${key}_groupId`] = file.response.data.groupId
        }
        this.$Message.success('上传成功')
      }
    },
    //获取所有动态表单的ref
    judgeRefs () {
      let refs = this.$refs.FormLayout
      if (!Array.isArray(refs)) {
        refs = [this.$refs.FormLayout]
      }
      // 有字段的表单，进行验证，否则表单校验过不去
      const req = refs.map(ref => {
        if (ref.layoutList.length > 0) {
          return ref.validateify()
        } else {
          return true
        }
      })
      return req
    },
    //将date对象格式化
    dateFormat (obj) {
      Object.keys(obj).forEach(item => {
        if (obj[item] instanceof Date) {
          obj[item] = timeFormat(obj[item])
        }
      })
      return obj
    },
    //提交
    submit () {
      Promise.all(this.judgeRefs()).then(res => {
        const result = res.every(v => v)
        if (!result) {
          this.$Message.error('表单校验失败')
          return
        }
        if (this.currentPrize.basicFormType == 'JCBDLX12') { //团队
          this.activityTeamSubmit(this.signUpId)
        } else { //个人
          this.activitySingleSubmit(this.signUpId)
        }
      })
    },
    //活动团队提交
    activityTeamSubmit (signUpId) {
      const params = this.dateFormat(cloneDeep(this.formItem))
      this.$ajax({
        url: `api-general/module/v2event/personalCenter/signUp/${signUpId ? 'editGroupSignUp' : 'submitGroupSignUp'}`,
        method: signUpId ? 'put' : 'post',
        data: {
          ...params,
          attachmentGroupId: this.uploadFile_groupId,
          photo: this.photo_groupId,
          teamMemberList: this.teamMembers,
          applicationObjectId: this.currentPrize.applicationObjectId,
          applicationInfoId: this.applicationInfoId,
          orgId: this.formItem.orgId || this.orgId,
        },
        headers: {
          'Content-Type': 'application/json'
        },
        disabledData: true
      }).then(({data}) => {
        this.$Message.success('提交成功')
        this.isDisabled = true
        this.getActivityGroupDetail(data.data.signUpId)
        this.setSignUpIdAfterSubmit(data.data.signUpId)
        this.updateHistory()
      })
    },
    //提交后设置本奖项的singUpId
    setSignUpIdAfterSubmit (signUpId) {
      const index = this.prizeList.findIndex(val => val.applicationObjectId == this.currentPrize.applicationObjectId)
      if (index > -1) {
        this.$set(this.prizeList[index], 'signUpId', signUpId)
        this.$set(this.applicationObjectList[index], 'signUpId', signUpId)
      }
    },
    //取消报名
    cancelTeamSignUp () {
      const isSingle = this.currentPrize.basicFormType == 'JCBDLX11'
      this.$ajax({
        url: `api-general/module/v2event/personalCenter/signUp/${isSingle ? 'cancelEventIndividualSignUp' : 'cancelEventGroupSignUp'}`,
        method: 'put',
        data: {
          signUpId: this.currentPrize.signUpId,
          orgId: this.orgId
        }
      }).then(({data}) => {
        this.$Message.success('取消成功')
        this.isDisabled = false
      })
    },
    //设置发展党员的参数
    setDevelopPartyMemberParams () {
      this.$set(this.listProps, 'listUrl', '/api-general/module/portal/user/getUserListNormal')
      this.$set(this.listProps, 'listData', {
        orgId: this.orgId,
        userTypes: this.currentPrize.allowPmdUser.split(',')
      })
    },
    //设置选人组件的参数
    setSelectMemberComponentProps () {
      if (this.bizLineCode === 'YWX03' || this.bizLineCode === 'YWX04' || this.bizLineCode === 'YWX05') {
        this.treeType = {
          type: 'async-change'
        }
        this.listProps = {
          async: true,
          type: 'checkbox',
          isAllResult: true,
          selectUserSwitch: true,
          bizLineCode: this.bizLineCode,
          treeData: {
            rootId: '',
            bizLineCode: this.bizLineCode,
          },
          listData: {
            orgId: ''
          },
          sendListData: (id, name) => {
            return {
              orgId: id,
              userName: name,
              drillType: 1
            }
          },
          listUrl: '/api-uum/uum/org/tree/single/listOrgUser'
        }
      } else if (this.bizLineCode === 'YWX01' || this.bizLineCode === 'YWX06') {
        this.treeType = {
          type: 'single-organize-life'
        }
        this.listProps = {
          async: true,
          type: 'checkbox',
          isAllResult: true,
          selectUserSwitch: true,
          bizLineCode: this.bizLineCode,
          listUrl: '/api-general/module/portal/user/listOrganizationUser',
          treeData: {
            orgId: this.orgId,
            bizLineCode: this.bizLineCode,
            scope: 2
          }
        }
      }
    }
  },
  components: {
    dragTable,
    FormLayout,
    LayoutTitle,
    SelectMember
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/base/variable.scss';
.iv-active-regist {
  min-height: 360px;
  .iv-active-regist-header {
    ul {
      display: flex;
      align-items: center;
      li {
        border: 1px solid;
        padding: 12px 20px;
        border-radius: 8px;
        &:hover {
          color: $iv-main-color !important;
          border-color: $iv-main-color !important;
        }
      }
      .iv-header-active-li {
        font-weight: bold;
        color: $iv-main-color !important;
        border-color: $iv-main-color !important;
      }
    }
  }
}
</style>
