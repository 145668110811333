<template>
  <Modal title="评审打分" v-model="isShowModal">
    <ModalLayout>
      <FormLayout :model="setInfo" :list="setForm"></FormLayout>
    </ModalLayout>
    <div slot="footer">
      <Button type="save" v-if="reportState != '1'" @click="saveInfo('save')">保存</Button>
      <!-- <Button type="submit" v-if="reportState != '1'" @click="submit">提交</Button> -->
      <Button type="close" @click="closePage">关闭</Button>
    </div>
  </Modal>
</template>

<script>
import setForm from './form.js'
import Modal from '@/components/modal'
import ModalLayout from '@/components/modal/layout'
import FormLayout from '@/components/layout/form'
import {get, saveScore, report, listScore} from '../interface'

export default {
  data () {
    return {
      isShowModal: false,
      dictMap: {},
      setInfo: {},
      reviewGroupInfo: {}, //评审组信息
      rankCode: '',
      setForm: [],
      reportState: ''
    }
  },
  async created () {
    this.userId = this.$decode(sessionStorage.getItem('userId'))
    this.businessCode = this.$decode(this.$route.query.businessCode)
    this.reviewTeamId = this.$decode(this.$route.query.reviewTeamId)
    this.reviewRoundId = this.$decode(this.$route.query.reviewRoundId)
    this.reviewDetailId = this.$decode(this.$route.query.reviewDetailId)
    this.reportState = this.$decode(this.$route.query.reportState)
    await this.getRevireGroupInfo()
    //只有定级才会请求数据字典
    if (this.reviewGroupInfo.scoringMethod === 'DFFS03') {
      await this.getCodeTable(this.rankCode)
    }
  },
  methods: {
    open () {
      this.getScoreList()
      this.setForm = setForm.call(this)
      this.isShowModal = true
    },
    closePage () {
      this.isShowModal = false
    },
    //获取评审组信息
    getRevireGroupInfo () {
      return new Promise((resolve, reject) => {
        get({reviewTeamId: this.reviewTeamId}).then(({data}) => {
          this.reviewGroupInfo = data
          this.rankCode = data.scoreFieldDict
          resolve()
        })
      })
    },
    //根据对应轮次获取打分列表信息
    getScoreList () {
      listScore({userId: this.userId, reviewRoundId: this.reviewRoundId}).then(({data}) => {
        const item = data.find(v => v.reviewDetailId === this.reviewDetailId)
        if (item) {
          this.setInfo = item
        }
      })
    },
    saveInfo (type) {
      const params = [{
        reviewDetailId: this.reviewDetailId,
        reviewScore: this.setInfo.reviewScore,
        reviewRank: this.setInfo.reviewRank,
        reviewVoteNum: this.setInfo.reviewVoteNum,
        reviewOpinion: this.setInfo.reviewOpinion,
      }]
      return new Promise((resolve, reject) => {
        saveScore({details: params}).then(({data}) => {
          if (type) {
            this.$Message.success('保存成功')
            this.closePage()
          }
          resolve()
        })
      })
    },
    async submit () {
      await this.saveInfo()
      report({reviewDetailIds: [this.reviewDetailId]}).then(({data}) => {
        this.$Message.success('提交成功')
        this.closePage()
      })
    },
    //获取数据字典
    getCodeTable (code) {
      this.$set(this.dictMap, code, {})
      return new Promise((resolve, reject) => {
        this.$ajax({
          url: 'api-general/module/portal/user/outGetList',
          method: 'get',
          data: {
            dictCode: code,
            merge: false,
            bizLineCode: this.bizLineCode || 'YWX01'
          }
        }).then(({data}) => {
          this.dictMap[code] = {}
          data.data.length &&  data.data.forEach(item => {
            if (!this.dictMap[code][item.value]) {
              this.dictMap[code][item.value] = item.title
            }
          })
          resolve()
        })
      })
    }
  },
  components: {
    Modal,
    ModalLayout,
    FormLayout
  }
}
</script>
