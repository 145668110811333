// import { prefixPath } from '@/config'
import { timeFormat } from '@/utils'

export default function (index) {
  const inputMaps = ['memberUserId', 'memberUserName', 'employeeNumber', 'expandFieldm', 'political', 'gender', 'projLeaderEmail', 'phone', 'birthday', 'age', 'jobDate', 'idCardNum', 'partyOrgName', 'joinPartyDate', 'nationality', 'nation', 'inDeptDate', 'workingAge', 'education', 'band', 'hrDuty', 'workPost', 'companyOrgName', 'workPostDate']
  const config = this.dynamicList[index]
  if (!config) return []
  const compMap = {
    'Input': 'input'
  }
  const list = config.formPropertyList.map(item => {
    if (item.propertyConfig && item.propertyConfig.indexOf('session_listOrgId') > -1) {
      item.propertyConfig = item.propertyConfig.replace('session_listOrgId', this.orgId)
    }
    if (item.propertyConfig && item.propertyConfig.indexOf('prefixPath') > -1) {
      item.propertyConfig = item.propertyConfig.replace('prefixPath', 'api-uum/')
    }
    const props = JSON.parse(item.propertyConfig || '{}')
    //特殊定制头像的上传
    if (item.widgetType === 'fileUpload' && item.propertyCode == 'photo') {
      item.widgetType = 'photo'
      props.src = this.photoSrc
    }
    //附件类
    if (item.widgetType === 'fileUpload') {
      props.data = props.data || {}
      props.data.groupId = this[`${item.propertyCode}_groupId`] || this.getUuid()
      props.isShowFileList = true
      props.accept = '.docx,.doc,.pdf,.xls,.xlsx,.jpg,.jpeg,.png,.ppt'
    }
    if (item.widgetType === 'InputNumber') {
      props.min = 0
    }
    //选人
    if (item.widgetType === 'selectInputMember') {
      props.bizLineCode = props.bizLineCode || this.bizLineCode
      props.isAllResult = props.isAllResult || true
    }
    //选组织
    if (item.widgetType === 'universalTree' && props.treeParams && props.treeParams.orgId === '') {
      props.treeParams.orgId = -1
    }
    if (item.widgetType === 'universalTree') {
      if (item.displayName === '单位名称') {
        props.treeParams = { bizLineCode: this.bizLineCode, scope: undefined, orgId: undefined }
        props.treeUrl = 'api-gongjian/module/partyhr/getHrOrgTree'
        props.isAsync = false
      } else {
        props.treeParams = props.treeParams || {}
        props.treeParams.bizLineCode = this.bizLineCode
      }
    }
    //团队表单所属组织  企宣下如果没有id 就显示全树
    if (this.currentType == 'YWX05' && item.propertyCode == 'orgName' && this.currentPrize.basicFormType == 'JCBDLX12' && !this.isExistOrg) {
      props.treeParams = Object.assign({}, props.treeParams || {}, {
        orgId: -1,
        isExistOrg: false
      })
    }
    // 更改userName为只读
    if (item.propertyCode === 'userName') {
      item.isReadonly = 1
    }
    //码表接口前缀
    if (props.url && props.url.indexOf('api-general') < 0 && props.params && props.params.dictCode) {
      props.url = 'api-general/module/portal/user/outGetList'
      props.params.bizLineCode = this.bizLineCode
    }
    if (item.description) {
      props.extra = true
    }
    if (config.groupType == 1) { //表单
      if (item.widgetType == 'Input') {
        if (!props.maxlength) {
          props.maxlength = 500
        }
      }
      if (item.widgetType == 'textarea') {
        if (!props.maxlength) {
          props.maxlength = 5000
        }
      }
      if (item.propertyCode == 'projLeaderName') {
        props.treeType = props.portalTreeType
        props.listUrl = props.portalListUrl
        props.sendListData = (id, name) => {
          return {
            orgId: id,
            userName: name,
            drillType: 1
          }
        }
      }
      return item.isEnabled == 1 ? {
        type: item.widgetType || 'Input',
        title: item.displayName || item.propertyName,
        [$field]: item.propertyCode,
        colSpan: item.colspan || 8,
        description: item.description || '',
        readOnly: item.isReadonly == 1 ? true : false,
        props: props,
        rules: item.isNotNull == 1 ? ['required'] : []
      } : null
    } else { //表格
      return item.isEnabled == 1 ? {
        showType: compMap[item.widgetType] || '',
        title: item.displayName || item.propertyName,
        [$field]: item.propertyCode,
        width: item.width || 'auto',
        minWidth: item.minWidth || 10,
        readOnly: item.isReadonly == 1 ? true : false,
        props: props,
        isShowOperateBtnForMiddle: true,
        render: (c, {row}) => {
          if (item.widgetType == 'DatePicker') {
            return c('span', timeFormat(row[item.propertyCode]), 'yyyy-MM-dd')
          } else if (item.widgetType == 'DatePickerTime') {
            return c('span', timeFormat(row[item.propertyCode]), item.format || 'yyyy-MM-dd HH:mm:ss')
          } else if (!inputMaps.includes(item.propertyCode)) {
            let value = row[item.propertyCode] || ''
            return c('Input', {
              props: {
                value: row[item.propertyCode]
              },
              style: 'width: 100%;',
              on: {
                'on-blur': () => {
                  this.teamMembers[row._index][item.propertyCode] = value || ''
                },
                'input': (val) => {
                  value = val
                }
              }
            })
          } else {
            return c('span', row[item.propertyCode])
          }
        }
      } : null
    }
  }).filter(v => v)
  //最后添加操作列
  // if (config.isEnabled == 1 && config.groupType == 2) {
  //   list.push({
  //     title: '操作',
  //     width: 120,
  //     [$field]: 'operate',
  //     isOperate: true
  //   })
  // }
  return config.isEnabled == 1 ? list : []
}